import React from "react";
import { ImageIntervalBox } from "./ImageIntervalBox";
import { Content } from "./Content";
import { MenuBar } from "./MenuBar";
import { TransitionComponent } from "./TransitionComponent";

import {
  images,
  imageChangeInterval,
  timingFunction,
  targetUrl,
  transitionTime,
} from "./config";

function App() {
  const [transition, setTransition] = React.useState(false);
  const handleTransitionEnd = () => {
    // 在转场结束后执行的操作
    window.open(targetUrl, "_self");
  };
  const triggerTransition = () => {
    setTransition(true);
  };

  return (
    <>
      <TransitionComponent
        transitionState={transition}
        onTransitionEnd={handleTransitionEnd}
        transitionTime={transitionTime}
      />
      <MenuBar></MenuBar>
      <ImageIntervalBox
        intervalTime={imageChangeInterval}
        images={images}
        timingFunction={timingFunction}
        // repeat
      ></ImageIntervalBox>
      <Content triggerTransition={triggerTransition}></Content>
    </>
  );
}

export default App;
