import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

export const TransitionComponent = ({
  transitionState,
  onTransitionEnd,
  transitionTime,
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    if (transitionState) {
      setIsTransitioning(true);
      const timer = setTimeout(() => {
        setIsTransitioning(false);
        if (onTransitionEnd) {
          onTransitionEnd();
        }
      }, transitionTime + 300);

      return () => clearTimeout(timer);
    }
  }, [transitionState, onTransitionEnd, transitionTime]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        transform: isTransitioning ? "scaleX(1.01)" : "scaleX(0)",
        transformOrigin: "center",
        transition: `transform ${transitionTime / 1000}s ease-in-out`,
        zIndex: 9999, // 确保在最上层
      }}
    />
  );
};
