import bg01 from "./assets/bg/bg01.jpg";
import bg02 from "./assets/bg/bg02.jpg";
import bg03 from "./assets/bg/bg03.jpg";
import bg04 from "./assets/bg/bg04.jpg";
import bg05 from "./assets/bg/bg05.jpg";

export const targetUrl =
  "http://pku-viewer.yuancj.com/drawing/cloe259vz01mywlewjxust27d";

function preloadImages(urls) {
  urls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
}
export const images = [bg01, bg02, bg03, bg04, bg05];

preloadImages(images);

export const imageChangeInterval = 2000;
export const timingFunction = "ease-in-out";
export const transitionTime = 2000;
